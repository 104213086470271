import type { FC, ReactElement } from 'react'
import { useState } from 'react'
import { useAuth } from '@components/auth/store/auth.store'
import { useUI } from '@contexts/ui/ui.context'
import { useIs } from '@src/hooks/useIs'
import { sodiumConfig } from '@src/sodiumConfig'

interface ProtectRouteProps {
  children?: ReactElement | null
}
export const ProtectedRoute: FC<ProtectRouteProps> = ({ children }) => {
  const { isAuthenticated } = useAuth()
  const { Modal } = useUI()
  const { isMainLanding } = useIs()

  const [isModalOpen, setIsModalOpen] = useState(false)

  if (!isAuthenticated()) {
    if (!isModalOpen && !isMainLanding && sodiumConfig.hasLandingPage) {
      Modal.openLogin()
      setIsModalOpen(true)
    }

    return null
  }

  return children ?? null
}
