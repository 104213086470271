import type { InputHTMLAttributes } from 'react'
import React, { forwardRef } from 'react'
import { useParseInputProps } from '@goatlab/react-zod-form'
import cn from 'clsx'
import { Controller } from 'react-hook-form'
import type { BaseField } from '../types/baseField'
import { Label } from '../Label'

type InputProps = BaseField<string> &
  InputHTMLAttributes<HTMLInputElement> & {
    inputClassName?: string
    shadow?: boolean
    variant?: 'normal' | 'solid' | 'outline'
  }

const defaultContainerClasses =
  'flex-1 mb-2 flex-col gap-1.5 placeholder:text-sm placeholder:text-gray-600 w-full'
const defaultInputClasses = 'bg-slate-100 py-2.5 px-4 rounded-lg text-black'

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      label,
      name: nameProp,
      placeholder,
      inputClassName,
      formHook: hook,
      defaultValue,
      disabled,
      transform,
      onChange,
      description,
      tabIndex,
      ...props
    },
    ref,
  ) => {
    const { name, formHook, errorMessage } = useParseInputProps({
      name: nameProp,
      formHook: hook,
    })

    const rootClassName = cn(
      defaultContainerClasses,
      defaultInputClasses,
      'focus:border-transparent focus:outline-none focus:ring-0',
      {
        'cursor-not-allowed bg-gray-200 text-slate-500': disabled,
      },
      inputClassName,
    )

    return (
      <div className={cn(className, 'w-full')}>
        {label && <Label text={label} />}
        <Controller
          control={formHook.control}
          name={name}
          defaultValue={defaultValue}
          render={({ field: { onChange: hookChange, ref: _, ...rest } }) => {
            return (
              <input
                ref={ref}
                id={name}
                tabIndex={tabIndex}
                type={'text'}
                placeholder={placeholder}
                disabled={disabled}
                className={rootClassName}
                autoComplete="off"
                inputMode="none"
                aria-invalid={errorMessage ? 'true' : 'false'}
                onChange={(event) => {
                  if (transform) {
                    event.target.value = transform(event.target.value)
                  }

                  onChange?.(event.target.value)
                  hookChange(event.target.value)
                }}
                {...props}
                {...rest}
              />
            )
          }}
        />

        <h4 className="mb-1 ml-2 mt-1 block text-xs font-medium">
          {description}
        </h4>

        {errorMessage && (
          <div className="mt-2 pl-1 text-xs text-red-600">
            {String(errorMessage)}
          </div>
        )}
      </div>
    )
  },
)

Input.displayName = 'Input'
