import type { FC } from 'react'
import { api } from '@api/api'
import { useAuth } from '@components/auth/store/auth.store'
import { Input } from '@components/ui/forms/fields/Input'
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '@components/ui/images/avatar'
import { useTypedTranslation } from '@goatlab/marketplace-i18n'
import { Form, useZodFormHook } from '@goatlab/react-zod-form'
import {
  useAccountStore,
  useCommentActivitiesStore,
  useHomeFeedStore,
} from '@sodium/shared-frontend-schemas'
import { motion } from 'framer-motion'
import { Send } from 'lucide-react'
import { z } from 'zod'

interface CommentInputProps {
  activityId?: string
  postId?: string
  onHandleComment?: () => void
}

export const commentSchema = z.object({
  comment: z.string(),
})

export const CommentInput: FC<CommentInputProps> = ({ activityId, postId }) => {
  const { user } = useAuth()
  const { selectedAccount } = useAccountStore()
  const { visibleAccountId } = useHomeFeedStore()
  const { resetCommentActivity } = useCommentActivitiesStore()

  const { t } = useTypedTranslation(['common', 'forms'])

  const formHook = useZodFormHook({
    schema: commentSchema,
    defaultValues: {
      comment: '',
    },
  })

  const handleReset = async () => {
    resetCommentActivity()
  }

  const postComment = api.posts.useCommentOnPost({
    account: selectedAccount,
    visibleAccountId,
    reset: handleReset,
  })

  const handleSubmitComment = async () => {
    const comment = formHook.getValues().comment

    if (!comment) {
      return
    }
    if (!selectedAccount) {
      return
    }

    if (!activityId && !postId) {
      return
    }

    if (!postId) {
      return
    }

    return await postComment.mutateAsync({
      postId,
      comment,
    })
  }

  return (
    <Form
      formHook={formHook}
      onSuccess={async () => {
        await handleSubmitComment()
        formHook.reset()
      }}
    >
      <div className="flex w-full items-center gap-2 p-4 align-middle">
        <Avatar className="h-10 w-10" data-cy="user-avatar">
          <AvatarImage
            src={user?.account?.profilePicture || user?.profilePicture}
            alt={user?.account?.firstName || undefined}
          />
          <AvatarFallback>{user?.account?.firstName}</AvatarFallback>
        </Avatar>
        <Input
          className="h-10 flex-grow items-center"
          name={formHook.path.comment}
          placeholder={t('placeholder-new-post', { ns: 'forms' })}
          formHook={formHook}
        />
        <motion.div
          className="flex aspect-square h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-gray-300 text-black transition-all first:ring-0 hover:bg-gray-400 active:scale-90"
          onClick={async () => {
            await handleSubmitComment()
            formHook.reset()
          }}
          whileTap={{ scale: 0.85 }}
        >
          <Send size={23} />
        </motion.div>
      </div>
    </Form>
  )
}
