import type { FC, ReactNode } from 'react'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useAuth } from '@components/auth/store/auth.store'
import { AccountDrawerLeft } from '@components/layout/drawerLeft/AccountDrawerLeft'
import { NoSSR } from '@components/ui/utils/NoSSR'
import { ProtectedRoute } from '@components/util/protectedRoute'
import { useSidebar } from '@goatlab/react-ui'
import { useAccountStore } from '@sodium/shared-frontend-schemas'
import { useIs } from '@src/hooks/useIs'
import clsx from 'clsx'

interface AccountLayoutProps {
  children?: ReactNode
  expanded?: boolean
  transparent?: boolean
}
export const AccountLayout: FC<AccountLayoutProps> = ({
  children,
  expanded,
  transparent,
}) => {
  const { user } = useAuth()
  const router = useRouter()
  const accountId = String(router.query.accountId)
  const { selectAccount, selectedAccount } = useAccountStore()
  const { state } = useSidebar()
  const { isMobile } = useIs()

  useEffect(() => {
    if (selectedAccount?.id === accountId) {
      return
    }

    const foundAccount = user?.account?.collaborations?.find(
      (org) => org.account.id === accountId,
    )

    const foundUser = user?.account?.id === accountId

    if (foundAccount) {
      selectAccount({
        id: accountId,
        displayName: foundAccount.account.displayName || undefined,
        profilePicture: foundAccount.account.logo || undefined,
        slug: foundAccount.account.slug || '',
        isAdvertisingAccount:
          foundAccount.account.isAdvertisingAccount || false,
      })
    } else if (foundUser) {
      selectAccount({
        id: accountId,
        displayName: user?.account?.displayName || undefined,
        slug: user?.account?.slug || undefined,
        profilePicture: user?.account?.profilePicture || undefined,
        isAdvertisingAccount: user?.account?.isAdvertisingAccount || undefined,
      })
    }
  }, [accountId, user, user?.account?.collaborations])

  return (
    <NoSSR>
      <ProtectedRoute>
        <div
          className="mx-auto h-full w-full flex-1 px-0 md:flex"
          style={{
            maxWidth: '1440px',
          }}
        >
          <AccountDrawerLeft />

          <div
            className={clsx('', {
              'pb-6 pt-20 md:pl-6 md:pr-6': !expanded,
              'pt-14 md:pl-2 md:pr-0': expanded,
              'sm:w-full md:w-4/5 lg:w-5/6': state === 'expanded',
              'w-full': state === 'collapsed',
            })}
          >
            <div
              className={clsx('flex flex-col', {
                'rounded-sm pb-6': !expanded,
                'border-none bg-transparent shadow-none': transparent,
                'bg-white shadow-lg': !transparent,
                'min-h-[90dvh]': isMobile,
                'min-h-[70dvh]': !isMobile,
                'pb-14': isMobile,
              })}
            >
              {children}
            </div>
          </div>
        </div>
      </ProtectedRoute>
    </NoSSR>
  )
}
