import type { HTMLReactParserOptions } from 'html-react-parser'
import type { FC } from 'react'
import { useState } from 'react'
import { ScrollToTop, ThemeSwitcher } from '@goatlab/react-ui'
import { HtmlProcessor } from '@sodium/shared-frontend-schemas'
import clsx from 'clsx'
import parse from 'html-react-parser'

interface RichTextDisplayProps {
  content?: string | null
  maxLength?: number
  ellipsis?: string
  textSize?: 'text-xs' | 'text-sm' | 'text-base' | 'text-lg' | 'text-xl'
}

export const RichTextDisplay: FC<RichTextDisplayProps> = ({
  content,
  maxLength,
  ellipsis,
  textSize = 'text-sm',
}) => {
  const [expanded, setExpanded] = useState(false)

  const MAX_LENGTH = maxLength || 150

  const expandKeyWord = ellipsis || '...See more '

  if (!content) {
    return null
  }

  const htmlProcessor = new HtmlProcessor({
    html: content,
    keywords: [expandKeyWord],
  })

  const finalHtml = htmlProcessor.getTruncatedHtml({
    truncate: expanded ? undefined : MAX_LENGTH,
    ellipsis: expandKeyWord,
  })

  const toggleExpanded = () => {
    setExpanded((expanded) => !expanded)
  }

  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (!('attribs' in domNode)) return undefined

      if ('attribs' in domNode) {
        const tagName = domNode.tagName.toLowerCase()

        // Fix: Ensure only `/expand` links are modified
        if (tagName === 'a' && domNode.attribs.href === '/expand') {
          return (
            <a
              className="cursor-pointer text-gray-500"
              onClick={toggleExpanded}
            >
              {expandKeyWord}
            </a>
          )
        }
      }

      const customComponent = (domNode as any)?.attribs?.[
        'data-custom-react-component'
      ]

      switch (customComponent) {
        case 'ThemeSwitcher': {
          return <ThemeSwitcher />
        }
        case 'ScrollToTop': {
          return <ScrollToTop />
        }
        default: {
          break
        }
      }

      return
    },
  }

  return (
    <div className={clsx(textSize)}>
      {parse(finalHtml || '', options)}
      {expanded && (
        <a className="cursor-pointer text-gray-500" onClick={toggleExpanded}>
          See less
        </a>
      )}
    </div>
  )
}
